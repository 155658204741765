<template>
  <div class="my-request-page">
    <div class="my-account__header">
      <h1>{{ $t("my-tickets-page.ticket.title") }} {{ getSingleTicket.id }} - {{ $t(`zendesk.${getSingleTicket.status}`)}}</h1>
    </div>
    <div class="my-account__messages">
      <div class="message">
        <ul>
          <li>
            {{ $t("Date") }}: {{ formatDate(getSingleTicket.created_at) }}
          </li>
          <li>{{ $t("Subject") }}: {{ getSingleTicket.subject }}</li>
          <li>
            {{ $t("Name") }}: {{ getUser.firstname }} {{ getUser.lastname }}
          </li>
          <li>
            <h4>{{ $t("my-tickets-page.comments")}}</h4>
          </li>
          <li
            class="message__text"
            v-for="item in getSingleTicket.messages"
            v-bind:key="item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <p class="message__textarea-label">
        {{ $t("my-tickets-page.message") }}*
      </p>
      <form @submit.prevent="formatData" novalidate>
        <!--  :placeholder="$t('Write your message') + ' *'" -->
        <base-textarea
          type="text"
          name="message"
          v-validate="'required|max:400'"
          v-model="message"
          :validations="[
            {
              condition: errors.has('message'),
              text: errors.first('message'),
            },
          ]"
        />
        <!-- <span id="charCount">{{ charCount }} {{ $t("characters left") }}</span> -->
        <div class="form-actions">
          <button
            type="button"
            class="back_btn b__icon--cursor d-flex align-items-center"
            @click="goBack"
            data-testid="returnButton"
          >
            <i class="icomoon-icon icon-arrow-left"></i>
            <span>{{ $t("Back") }}</span>
          </button>
          <button class="btn btn-primary" type="submit">
            <span>{{ $t("Send") }}</span>
          </button>
          <div class="d-block d-md-none"></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Logger from '@/services/Logger'
import BaseTextarea from 'theme/components/Form/BaseTextarea'
import dayjs from 'dayjs'
import { toast } from '@/modules/notifications'
import { mapGetters } from 'vuex'

export default {
  inject: ['$validator'],
  name: 'TicketDetail',
  data () {
    return {
      routeId: this.$route.params.id * 1,
      messageChar: 400,
      message: ''
    }
  },
  methods: {
    formatDate: function (date) {
      return dayjs(date).format(this.$t('dateFormat'))
    },
    goBack () {
      this.$router.back()
    },
    getTicketFromState () {
      return this.$store.state.zendesk.userTickets.find(
        (e) => e.id === this.routeId
      )
    },
    getUserFromState () {
      return this.$store.state.user.current
    },
    formatData (event) {
      const _this = this
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$store.commit('ui/showLoader')
          let requestObject = {
            ticketData: {
              ticket_id: this.getTicketFromState().id,
              comment: this.message,
              firstName: this.getUserFromState().firstname,
              lastName: this.getUserFromState().lastname,
              email: this.getUserFromState().email,
              country: 'country_' + this.$store.state.ui.currentCountry.country,
              storeCode: this.$store.state.ui.storeViewCode
            },
            store: this.$store.state.ui.storeViewCode
          }
          event.target.reset()
          _this.$validator.reset()
          _this.message = ''
          this.$store
            .dispatch('zendesk/updateTicketMessage', requestObject)
            .then((res) => {
              this.$store.commit('ui/hideLoader')
              if (res && res.status === 200) {
                toast.success(_this.$t('Request successful'))
              } else {
                Logger.error(res)
                toast.error(_this.$t(res.data.error_messages))
              }
            })
            .catch((err) => {
              this.$store.commit('ui/hideLoader')
              Logger.error(err)
              const data = err.response.data
              if (data && data.message) {
                toast.error(_this.$t('closed-tkt-error'))
              } else {
                toast.error(_this.$t('closed-tkt-error'))
              }
            })
          _this.clearForm()
        }
      })
    }
  },
  computed: {
    ...mapGetters('zendesk', ['ticketHistory']),
    getUser () {
      return this.getUserFromState()
    },
    charCount () {
      if (this.message) {
        if (this.message.length >= 400) {
          return 0
        } else {
          return this.messageChar - this.message.length
        }
      }
      return this.messageChar
    },
    getSingleTicket () {
      return this.getTicketFromState()
    }
  },
  components: {
    BaseTextarea
  }
}
</script>

<style lang="scss">
.my-account {
  &__header {
    margin-bottom: 32px;
  }
  &__messages {
    .message {
      &__text {
        margin-top: 10px;
      }
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 24px;
      margin-top: 12px;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          line-height: 1.5rem;
        }
      }
    }
    textarea[name="message"] {
      transition: all 0.5s cubic-bezier(0.16, 0.84, 0.25, 0.93);
      font-size: 13px;
      color: #000;
      background-color: #fff;
      border: 1px solid rgb(118, 118, 118);
      border-radius: 0;
      min-height: 81px;
      width: 100%;
      height: 100%;
      max-width: 595px;
      display: block;
      margin: 0;
      padding: 20px;
      appearance: none;
    }
    .form-actions {
      margin-top: 80px;
      margin-bottom: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .back_btn {
        border: 0;
        background: none;
        padding: 0;
        line-height: 1;
        width: 100%;
        max-width: 50px;
        justify-content: space-between;

        i {
          margin-right: 10px;
        }

        span {
          font-size: 13px;
        }
      }

      button[type="submit"] {

        padding: 0px 7px;
        height: 19px;
        line-height: 1px;

        span {
          font-size: 13px;
        }

        margin-right: 40px;
      }
    }
  }
}
</style>
